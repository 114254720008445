const messages = {
  es: {
    translations: {
      titles: {
        home: 'Home',
        contents: 'Contenido',
        integration: 'Integración',
        contact: 'Contacto',
        compliance: 'Compliance',
        signup: 'Registrarse',
        signupSuccess: 'Inscripción Realizada',
        signin: 'Iniciar sesión',
        login: 'Inicio de sesión',
        finalizeAccount: 'Complete su registro',
        guardianData: 'Datos del Tutor Legal',
        forgotPassword: 'Olvide mi contraseña',
        searchResults: 'Resultados de la búsqueda',
        pageNotFound: 'Página no encontrada',
        adjustImage: 'Ajusta tu foto',
        account: 'Perfil',
        admin: 'Admin',
        help: 'Ayuda',
        rate: 'Evaluar',
        rated: 'Evaluado',
        rateQuestion: '¿Qué te pareció este contenido?',
        profileData: 'Datos personales',
        permissions: 'Notificaciones y permisos',
        plan: 'Plan',
        config: 'Configuración',
        mouse: 'Ratón',
        keyboard: 'Teclado',
        trackpad: 'Panel Táctil',
        organs: 'Cuerpos y Sistemas',
        completeAnatomy: 'Anatomía Completa',
        topographicAnatomy: 'Anatomía Topográfica',
        systemicAnatomy: 'Anatomía Sistémica',
        cellStructure: 'Células y tejidos',
        cytology: 'Citología',
        histology: 'Histología',
        development: 'Funciones corporales y desarrollo',
        embryology: 'Embriología',
        physiology: 'Fisiología',
        specialties: 'Clínica Integrada',
        radiology: 'Radiología',
        pathology: 'Patología',
        simulator: 'Simulador',
        api: 'API BioAtlas',
        institutions: 'Instituciones',
        whiteLabel: 'White Label',
        VR: 'Realidad Virtual 3D',
        multiplatform: 'Acceso multiplataforma',
        animations: 'Animaciones interactivas en 3D',
        accessControl: 'Control de acceso',
        languages: 'Disponible en 3 idiomas',
        platform:
          'Plataforma de contenido digital para facilitar la Enseñanza en Salud',
        modules:
          'Varios módulos de Enseñanza para la Salud en una sola plataforma',
        cadaver: 'Cadáver Virtual',
        cadaver3D: 'Cádaver Virtual 3D',
        atlas3D: 'Atlas Anatómico 3D',
        blades: 'Láminas Histológicas y Histopatológicas',
        simulations: 'Simulación médica',
        cardiacCycle: 'Ciclo cardíaco del corazón',
        fiberContraction: 'Contracción de la fibra muscular',
        potentialGeneration: 'Generación y conducción del potencial de acción',
        hematosis: 'Hematosis',
        synapticTransmission: 'Transmisión sináptica',
        lungVentilation: 'Ventilación pulmonar',
        pathophysiology: 'Fisiopatologia',
        sickleAnemia: 'Anemia de células falciformes',
        diabetes1: 'Diabete Mellitus Tipo 01',
        diabetes2: 'Diabete Mellitus Tipo 02',
        histopathology: 'Histopatologia',
        respiratoryDiseases: 'Enfermedades del Sistema Respiratorio',
        vascularDiseases: 'Enfermedades del Sistema Cardiovascular',
        bloodDiseases: 'Trastornos de la Circulación Sanguínea',
        tumor: 'Tumor',
        brainDiseases: 'Enfermedades del Sistema Nervioso',
        genitalDiseases: 'Enfermedades del Sistema Genital',
        inflammation: 'Inflamación',
        lesions: 'Lesión y Reparación de Células y Tejidos',
        endocrineDiseases: 'Enfermedades del Sistema Endocrino',
        immuneDiseases: 'Enfermedades Inmunes',
        boneDiseases: 'Enfermedades de los Sistemas Muscular y Esquelético',
        cardiology: 'Cardiologia / Urgencias cardíacas',
        firstAid: 'Primeros auxilios',
        pulmonology: 'Neumología',
        infectology: 'Infectología',
        neurology: 'Neurología',
        vascular: 'Vasculares',
        autoImmune: 'Reumatismo / Autoinmune',
        survey: 'Hola, ¿nos ayudan a mejorar BioAtlas?',
        collections: 'Mi colección',
        deleteCollections: 'Desea eliminar '
      },
      subtitles: {
        signup: 'Rellene la información para registrarse',
        validationSent: 'Información enviada por correo electrónico',
        signin: 'Inicie la sesión para acceder a su cuenta',
        finalizeAccount: 'Rellene el resto de la información',
        fillInfo: 'Complete la información a continuación:',
        resetPassword: 'Necesitas cambiar tu contraseña:',
        resetToken: 'Recibirás un token para cambiar tu contraseña',
        pageNotFound: 'Lo sentimos, hubo un error en nuestra página.',
        rightsReserved: 'Todos los derechos reservados.',
        tabText: 'Contenido Digital Interactivo para la Salud',
        group: 'Somos una empresa del Grupo Afya',
        sales: 'Póngase en contacto con nuestro equipo de ventas',
        detail: 'Informaciones',
        rotate: 'Girar',
        moreTools: 'Más herramientas',
        pin: 'Pin',
        ruler: 'Escala',
        arrow: 'Flecha',
        savePin: 'Guardar Pin',
        saveArrow: 'Guardar Flecha',
        saveRectangle: 'Guardar Rectángulo',
        fullscreen: 'Pantalla completa',
        info: 'Informaciones',
        move: 'Mover',
        select: 'Seleccionar',
        camera: 'Mover cámara',
        zoom: 'Zoom',
        resetZoom: 'Restablecer zoom',
        deselect: 'Eliminar selección',
        focus: 'Centrarse',
        selectMultiple: 'Seleccione varios',
        hide: 'Esconder',
        isolate: 'Isolar',
        tree: 'Árbol de anatomía',
        textbox: 'Cuadro de texto',
        tag: 'Etiqueta',
        cutTool: 'Herramienta de recorte',
        turn: 'Voltear',
        menu: 'Menu',
        increaseZoom: 'Aumentar zoom',
        decreaseZoom: 'Disminuir zoom',
        originalZoom: 'Volver a la versión original',
        bladeMessage:
          'Para que funcionen los atajos de teclado, primero haga clic en la imagen y luego presione la tecla',
        organ: 'Organo',
        tissue: 'Tejido',
        color: 'Colorante',
        section: 'Sección',
        partners1: 'Únete a más de',
        partners2: 'usuarios y',
        partners3: 'instituciones educativas',
        completeAnatomy:
          'Explore el cuerpo humano en 3D a través del Cadáver virtual o el Atlas anatómico en 3D.',
        completeAnatomy2:
          'La Anatomía Humana es la ciencia que estudia la morfología y organización del cuerpo humano, a través de la observación y análisis de las estructuras macroscópicas constituyentes. Este análisis está correlacionado con el funcionamiento de las diversas partes y los mecanismos de formación y en respuesta a factores ambientales, genéticos y temporales. Se puede categorizar, a partir de la separación de las partes, en Sistemas Orgánicos y Regiones Anatómicas. En este módulo se contemplaron modelos anatómicos representativos del cuerpo masculino y femenino, además del cadáver virtual masculino.',
        topographicAnatomy:
          'Interactúe con una descripción general de anatomía 3D etiquetada y explore la macroanatomía.',
        topographicAnatomy2:
          'La Anatomía Topográfica es el estudio de la separación del cuerpo humano en regiones, con el objetivo de facilitar la comprensión de las relaciones anatómicas entre las estructuras de todos los sistemas en una parte del cuerpo. Este método permite estudiar la topografía general de los órganos en función de la percepción del funcionamiento y la ubicación de todas las partes cercanas correlacionadas. En este módulo se contemplaron las regiones anatómicas: Cabeza y Cuello, Tórax, Abdomen, Pelvis, Espalda, Miembros Superiores e Inferiores.',
        topographicAnatomy3:
          'Estudie los Elementos Descriptivos del Cráneo y regiones anatómicas: cabeza y cuello, miembro superior derecho, miembro superior izquierdo, espalda, tórax, abdomen, pelvis, miembro inferior derecho y miembro inferior izquierdo.',
        systemicAnatomy:
          'Analiza todas las regiones anatómicas separadas por órganos y por sistemas.',
        systemicAnatomy2:
          'La Anatomía Sistémica se ocupa del estudio analítico de los sistemas orgánicos del cuerpo humano, estableciendo conjuntos de órganos y partes anatómicas que realizan, de manera integrada, las mismas funciones en los mecanismos biológicos. Aunque cada sistema realiza una tarea. Este módulo considera los siguientes sistemas: Arterial, Articular, Digestivo, Endocrino, Esquelético, Linfático, Muscular, Nervioso, Respiratorio, Tegumentario, Urogenital y Venoso.',
        systemicAnatomy3:
          'Sistemas anatómicos: tegumentario, muscular, esquelético, articular, nervioso, linfático, digestivo, respiratorio, arterial, venoso, endocrino y urogenital.',
        cytology:
          'Navegue a través de las células BioAtlas virtual 3D y descubra más de este universo a simple vista.',
        cytology2:
          'La Citología, también conocida como Biología Celular, es la ciencia que estudia las células y sus orgánulos, así como sus funciones e importancia en la constitución de los seres vivos. Considerada como la unidad fisiológica y morfológica de los organismos, la célula eucariota incluye varias estructuras más pequeñas con funciones metabólicas específicas -los orgánulos- encargados de garantizar el correcto funcionamiento de los mecanismos biológicos. Para estudiar estas estructuras invisibles a simple vista, es necesario utilizar microscopios ópticos, equipo que se utiliza para ampliar la imagen.',
        cytology3:
          'El módulo de Citología permite al usuario interactuar con dos categorías de contenidos 3D: Células y Membrana celular. Explorar células: Célula Adiposa, Célula Eucariota de origen animal, Célula Sanguínea, Islotes de Langerhans, Neurona y Fibra Muscular. Visualice un modelo de membrana celular en 3D y animaciones relacionadas con el transporte de moléculas a través de la membrana: difusión simple, difusión facilitada, bomba de sodio y potasio, cotransporte y contratransporte.',
        histology:
          'Un laboratorio de microscopía virtual a tu disposición. Explore diferentes tipos de tejidos, sistemas y órganos sensoriales con todas las capacidades de un microscopio.',
        histology2:
          'La histología es la ciencia que estudia la formación y composición de las células, órganos y tejidos biológicos, además de investigar sus funciones individuales y cómo se relacionan con el bueno funcionamiento del organismo. El estudio de estas estructuras microscópicas se elabora a partir de cortes muy finos de tejido biológico, previamente fijados para su conservación, en seguida, se aplica el material de estudio en láminas de vidrio y se realiza el proceso de tinción, resaltando los componentes de dicho tejido, con los portaobjetos histológicos listos se procede con el análisis en microscopios ópticos, equipo utilizado para ampliar la imagen.',
        histology3:
          'BioAtlas dispone de un módulo dedicado al estudio de láminas de glándulas, órganos, tejidos y sistemas, y láminas con patologías. Además de disponer de una herramienta con recursos para su visualización y análisis con Zoom de hasta 40x, con posibilidad de marcación de puntos en las láminas, función espejo, etc.',
        embryology:
          'Siga el desarrollo embrionario y fetal de manera interactiva, desde la 4ª semana de embarazo hasta el nacimiento, a través de animaciones 3D interactivas.',
        embryology2:
          'La Embriología Humana es la ciencia que estudia el desarrollo embrionario del ser humano, desde la fecundación hasta el nacimiento, caracterizando cada etapa de formación de los individuos. Por lo tanto, la embriología analiza todos los procesos que ocurren en el crecimiento embrionario, considerando desde la fase de segmentación celular hasta el final de la formación de todos los órganos y tejidos. Para esto, utiliza muchos conceptos de otras áreas del conocimiento como la Citología, Anatomía, Histología, Fisiología y otras. En este módulo se estudia la etapa de organogénesis desde la cuarta semana de desarrollo embrionario.',
        embryology3:
          'Siga el desarrollo embrionario y fetal, desde la cuarta semana de gestación hasta el nacimiento, a través de animaciones 3D interactivas del Período de desarrollo embrionario y el Período de desarrollo fetal.',
        physiology:
          'Distintos modelos fisiológicos animados permiten al usuario visualizar, interactuar y comprender el funcionamiento del cuerpo humano en 3D, además de seguir las etapas de su desarrollo con descripciones.',
        physiology2:
          'La Fisiología Humana es la ciencia que estudia el funcionamiento del cuerpo humano a través de procesos biológicos internos, integrando conocimientos físico-químico y anatómicos para comprender el comportamiento del organismo en su conjunto. Estos procesos fisiológicos ocurren en células, tejidos, órganos y sistemas del cuerpo, mostrando las interacciones entre ellos y también la interacción con el medio externo, por lo tanto, el campo de la Fisiología incluye contenidos microscópicos, a nivel molecular y celular, y macroscópicos.',
        physiology3:
          'Explore el funcionamiento y las funciones normales del cuerpo humano a través de contenido virtual interactivo en 3D:',
        radiology:
          'Visualiza exámenes de diferentes modalidades como Tomografías Computarizadas, Resonancias Magnéticas, Rayos X, entre otros.',
        radiology2:
          'Sistema de visualización de imágenes DICOM con herramientas para el manejo de estudios radiológicos y casos clínicos. Permite visualizar imágenes de diferentes modalidades como TAC, Resonancias Magnéticas, Rayos X, entre otras a través del navegador web.',
        pathology:
          'Modelos 3D virtuales y láminas histológicas que permiten el estudio de diferentes alteraciones morfológicas y funcionales del cuerpo humano. Promueve el desarrollo del razonamiento clínico-epidemiológico.',
        pathology2:
          'La patología es la ciencia que estudia las enfermedades, considerando sus mecanismos básicos y las alteraciones estructurales, bioquímicas y funcionales en las células, tejidos y órganos asociados. Además, también estudia los síntomas y manifestaciones clínicas en los pacientes. Procesos patológicos y trastornos pueden ser reconocidos a partir de la comparación con los procesos saludables, utilizando conocimientos de otras ciencias, como Fisiología, Citología, Histología, etc. Así, la patología actúa uniendo las ciencias básicas y la medicina clínica, sirviendo de base científica para la formación en salud.',
        pathology3:
          'El módulo de Patología se divide en dos categorías: histopatología y fisiopatología. La primera categoría incluye 100 láminas histológicas que muestran patologías en tejidos y células, de diferentes sistemas y regiones anatómicas del cuerpo humano. La segunda categoría incluye animaciones tridimensionales que representan procesos fisiológicos no saludables (patológicos), demostrando los cambios que ocurren en el cuerpo bajo estas condiciones.',
        simulator:
          'Simulador virtual para experimentar situaciones de emergencia en la práctica en la clínica médica.',
        simulator2:
          'Combinando la gamificación con la formación en salud, ofrecemos un módulo de Simulación Clínica 100% digital que ofrece la sensación de una experiencia presencial, totalmente enfocada a la toma de decisiones del alumno. Aprenda a través de casos virtuales a salvar a pacientes reales, a partir de problemas que promuevan el razonamiento clínico.',
        features:
          '¡Explore los detalles del cuerpo humano en 3D de manera interactiva en cualquier momento y en cualquier lugar!',
        VR: 'Involucrar a estudiantes y profesores a través del entorno inmersivo de Realidad Virtual',
        multiplatform:
          'Acceso a través de cualquier navegador web, aplicación de Android o iOS',
        animations:
          'Diversos contenidos con animaciones 3D guiadas para facilitar la comprensión',
        accessControl:
          'Habilite, controle y analice el acceso de profesores y alumnos',
        integration:
          'Integre nuestro contenido en su página web a través de nuestra API',
        languages:
          'Contenido mapeado y traducido en portugués, español e inglés',
        testimonial0_0:
          'BioAtlas es una excelente herramienta para que el estudiante integre los diferentes contenidos de las ciencias biológicas y de la salud',
        testimonial0_1: 'Profesor de Fisiología Humana y Neurociencia en FMIT',
        newsletter:
          'Conozca BioAtlas y sus posibilidades, asegurando el conocimiento en Salud de su Institución en cualquier escenario de aprendizaje.',
        platform:
          'Mejore el aprendizaje de las disciplinas de la salud humana mediante el uso de contenido digital 3D interactivo en una plataforma unificada.',
        modules:
          'Un entorno virtual con miles de posibilidades. Contenidos relacionados con la enseñanza de la anatomía humana, como el Atlas Anatómico Humano Virtual 3D, el Cadáver Virtual 3D, contenidos de Fisiología, Histología, Embriología, Citología, Simulación Médica y varios otros.',
        cadaver:
          'Tener disponible una reconstrucción 3D de un cuerpo humano real para la disección virtual y complementar la enseñanza del laboratorio de anatomía.',
        cadaver3D:
          'El cadáver virtual ilustra la anatomía de un cuerpo masculino real, con sus proporciones y estructuras bien conservadas que revelan los detalles de la morfología interna del organismo. Después de congelar y diseccionar el cuerpo, todas las partes anatómicas fueron fotografiadas y reconstruidas en 3D para facilitar la visualización y el estudio de la anatomía humana.',
        atlas3D:
          'El Atlas Humano reúne las estructuras anatómicas que representan el cuerpo humano. La mayoría de las partes son similares a las del cuerpo masculino y femenino, como los sistemas respiratorio, digestivo, muscular, articular, esquelético, linfático y endocrino. En los sistemas Urogenital, Arterial, Venoso, Nervioso y Tegumentario, algunas estructuras son diferentes, caracterizando cada sexo biológico.',
        blades:
          '¡Más de 200 láminas digitalizadas, permitiendo ampliar hasta 40x, realizar marcaciones y mucho más!',
        simulations:
          'Simulador virtual para vivir situaciones de emergencia en la práctica en la clínica médica en un entorno 100% interactivo.',
        contents1:
          'Explore el cuerpo humano en detalles a través de modelos virtuales interactivos en 3D',
        contents2:
          'Contenidos que facilitan el aprendizaje de las disciplinas de la salud humana a través de la interactividad. Integra las áreas de Ciencias Morfológicas, Ciencias Fisiológicas, Ciencias Humanas y Ciencias Sociales Aplicadas a la Salud.',
        organs:
          'Aprenda sobre la anatomía humana de manera interactiva a través de los atlas anatómicos masculino y femenino, y a través del cadáver virtual: una reconstrucción en 3D de un cuerpo humano real. El estudio macroscópico del cuerpo humano se organiza en anatomía completa, que presenta los sistemas y partes anatómicas de manera integrada; anatomía sistémica y anatomía topográfica, que estudian estructuras que forman sistemas orgánicos y regiones anatómicas.',
        cellStructure:
          'Explore las células del cuerpo humano a través de modelos 3D interactivos, explorando los orgánulos que componen los diferentes tipos de células y sus funciones. Además, un conjunto de láminas histológicas que permiten al usuario ver de manera microscópica varias estructuras corporales, como glándulas, órganos y tejidos.',
        development:
          'Estudie fisiología y embriología humana a través de contenido tridimensional virtual que exploran diferentes procesos fisiológicos y etapas del desarrollo embrionario y desarrollo fetal. Los modelos animados permiten al usuario visualizar y comprender el funcionamiento del cuerpo humano, además de demostrar las etapas de su desarrollo, desde la 4ª semana de gestación hasta su nacimiento.',
        specialties:
          'Practique el conocimiento médico de forma práctica desde diferentes perspectivas. La radiología se puede estudiar a través de imágenes DICOM, con herramientas para el manejo de casos clínicos. Conoce el módulo de patología, que utiliza modelos virtuales 3D y láminas histológicas que permiten el estudio de diversas alteraciones morfológicas y funcionales del cuerpo humano. Además, acceda al simulador virtual para experimentar situaciones de emergencia en la clínica médica en la práctica.',
        survey:
          'Te invitamos a llenar un breve formulario, es sencillo y rápido.',
        subtitleSuccessShareCollection: 'E-mails con acceso',
        subtitleErrorShareCollection: 'Error en los seguientes e-mails'

      },
      placeholders: {
        suggestion: 'Deja tu sugerencia',
        email: 'Ingrese su correo electrónico',
        phone: 'Ingrese su teléfono',
        password: 'Ingrese su contraseña',
        newPassword: 'Crea tu contraseña',
        token: 'Ingresar código',
        name: 'Ingrese su nombre',
        lastName: 'Ingrese su apellido',
        idNumber: 'Ingrese ID (solo números)',
        search: 'Buscar',
        searchQuestion: '¿Qué contenidos quieres estudiar?',
        resultsFor: 'Resultados para',
        find: 'Búsqueda',
        country: 'Ingrese su pais',
        profession: 'Ingrese su profesión',
        countrySelect: 'Selecciona tu pais',
        professionSelect: 'Selecciona tu profesión',
        institution:
          'Ingrese el nombre de la institución o empresa a la que pertence',
        departmentSelect: 'Elige un departamento',
        message: 'Escriba su mensaje aquí',
        defaultCollectionTitle: 'Sin título',
        renameCollection: 'Agregar nombre para una nueva carpeta o elemento',
        moveCollection: 'Seleccione la carpeta que desea mover',
        shareCollection: 'Agregar e-mail con qual quiera compartir',
        createFolder: 'Agregar nombre de la nueva carpeta',
        newContent: 'Que hay de nuevo'
      },
      labels: {
        email: 'Correo electrónico',
        password: 'Contraseña',
        newPassword: 'Nueva contraseña',
        oldPassword: 'Contraseña actual',
        repeatPassword: 'Repetir contraseña',
        token: 'Ingrese el código recibido',
        firstName: 'Nombre',
        lastName: 'Apellido',
        fullName: 'Nombre completo',
        guardianName: 'Nombre del Tutor Legal',
        guardianIdNumber: 'ID del Tutor Legal',
        minorName: 'Nombre del menor',
        country: 'Pais',
        profession: 'Profesión',
        institution: 'Nombre de la institución o empresa',
        department: '¿Con qué departamento quieres hablar?',
        isAfya: '¿Su institución pertenece al Grupo Afya?',
        message: 'Mensaje',
        phone: 'Teléfono',
        mobile: 'Teléfono celular',
        language: 'Idioma',
        pt: 'Português',
        es: 'Español',
        en: 'English',
        undefined: 'Indefinido',
        description: 'Descripción',
        details: 'Detalles',
        forbidden: 'Módulo no liberado',
        account: 'Cuenta',
        personalAccount: 'Cuenta Personal',
        corporateAccount: 'Cuenta Corporativa',
        legal: 'Jurídico',
        useTerms: 'Condiciones de Uso',
        politics: 'Política de Privacidad',
        cookies: 'Política de Cookies',
        requests: 'Órdenes del titular',
        dragFile: 'Arrastrar o',
        uploadFile: 'sube tu archivo aquí',
        availability: 'Disponible:',
        contactWhats: '+55 11 96340-0766',
        contactEmail: 'comercial@medicalharbour.com',
        newModule: 'Nuevo módulo',
        new: 'Nuevo',
        updated: 'Actualizado',
        newContent: 'Nuevo contenido',
        step: 'Paso',
        renameCollection: 'Nombre de carpeta o elemento',
        moveCollection: 'Mover para',
        shareCollection: 'Compartir carpeta o elemento',
        createdAt: 'Creado el',
        folderName: 'Nombre de la carpeta'
      },
      buttons: {
        signup: 'Registrar',
        signin: 'Iniciar sesión',
        enter: 'Acceder',
        save: 'Guardar',
        cancel: 'Cancelar',
        return: 'Regresar',
        exit: 'Salir',
        edit: 'Editar',
        update: 'Actualizar',
        view: 'Visualizar',
        subscribe: 'Suscribir',
        send: 'Enviar',
        adjust: 'Ajustar',
        remember: 'Recuérdame',
        sendData: 'Enviar Datos',
        sendToken: 'Enviar correo electrónico para restablecer',
        resendToken: 'Reenviar código',
        resetPassword: 'Restablecer contraseña',
        changePassword: 'Cambiar Contraseña',
        changeProfile: 'Cambiar Datos',
        changeLanguage: 'Cambiar Idioma',
        changeAvatar: 'Cambiar Foto',
        deleteAccount: 'Borrar Cuenta',
        confirmDelete: 'Borrar de todos modos',
        cancelDelete: 'No borres',
        viewPlan: 'Consultar todos los servicios de su Plan',
        finalizeAccount: 'Finalizar Registro',
        navigation: 'Nociones de Navegación',
        shortcuts: 'Atajos de Teclado',
        demo: 'Solicitar una demostración',
        discover: 'Quiero conocer el BioAtlas',
        openNewTab: 'El enlace se abre en una nueva pestaña.',
        getNews: 'Recibir noticias',
        knowMore: 'Más información',
        knowContent: '$t(buttons.knowMore) sobre nuestro contenido',
        knowModules: '$t(buttons.knowMore) sobre nuestros módulos',
        knowCadaver: '$t(buttons.knowMore) sobre el cadáver virtual',
        askDemo: 'Solicita una demo para su institución',
        askDemoNow: 'Solicita ahora la demo para tu institución',
        surveyNo: 'No gracias',
        surveyYes: 'Hagamos el reto',
        seeContent: 'Visualizar contenido',
        keepReading: 'continuar leyendo en el contenido',
        relatedContent: 'Contenidos Relacionados',
        bibliography: 'Bibliografía',
        share: 'Compartir',
        rename: 'Renombrar',
        duplicate: 'Duplicar',
        move: 'Mover',
        delete: 'Eliminar',
        createFolder: 'Crear carpeta',
        sendFeedback: 'Enviar feedback',
        seeAll: 'ver todo'
      },
      messages: {
        conjunction: 'y',
        separator: 'o',
        agreeTerms1: 'Al registrarme, acepto que he leído y aceptado las',
        agreeTerms2: 'Acepto que he leído y aceptado las',
        agreeTermsMinorS:
          'Soy menor de 18 años y mi tutor legal autoriza a Medical Harbor a recopilar mis datos personales.',
        agreeTermsMinorL:
          'Soy menor de 18 años y mi tutor legal autoriza a Medical Harbor a recopilar mis datos personales a efectos de comunicaciones electrónicas de ofertas y servicios (SMS, mensajería instantánea, teléfono, correo electrónico) de acuerdo con la $t(labels.politics).',
        validationSent:
          '¡Le enviamos un correo electrónico para validar su registro!',
        contactAdmin: '¡Póngase en contacto con el administrador del sistema!',
        accountReady: '¡Su acceso a BioAtlas está activo!',
        dataSharing: 'Acepte el intercambio de datos para ofertas de productos',
        notifications:
          '¿Acepta recibir notificaciones por SMS, Whatsapp o correo electrónico?',
        deleteQuestion:
          '¿Está seguro de que desea eliminar permanentemente su cuenta?',
        deleteReminder1:
          'Eliminar su cuenta de BioAtlas es una acción permanente y no se puede deshacer.',
        deleteReminder2:
          'Una vez que elimine su cuenta, no podrá volver a iniciar sesión en la aplicación y cualquier modelo 3D que haya creado y guardado en su biblioteca se eliminará de nuestra base de datos de forma permanente y no se podrá restaurar.',
        trialWarning:
          'Estás usando la versión de prueba. Todavía falta {{count}} día.',
        trialWarning_plural:
          'Estás usando la versión de prueba. Todavía quedan {{count}} días.',
        trialUpgrade: 'Obtener la versión PRO.',
        noContent: 'No se encontró contenido para',
        newContent: 'Nuevo contenido que te puede interesar',
        noAccount: '¿Aún no tienes una cuenta?',
        gotAccount: '¿Ya tienes una cuenta?',
        passwordsDontMatch: '¡Las contraseñas deben coincidirse!',
        notYetValidated:
          '¡Aún no ha validado su registro por el correo electrónico que se envió!',
        notYetConfirmed:
          'Aún no has confirmado tu registro. Consultar su correo electrónico.',
        confirmAccount: 'Confirme su cuenta de BioAtlas',
        notYetConfirmed1: 'Antes de cambiar su contraseña,',
        notYetConfirmed2: 'Antes de iniciar sesión,',
        notYetConfirmed3: 'confirme su cuenta por correo electrónico.',
        notYetConfirmed4:
          'No olvides revisar también tu casilla de correo no deseado, ¿de acuerdo?',
        checkData: 'Revisa tus datos e intenta de nuevo',
        loginInvalid: 'Nombre de usuario y/o contraseña no válidos',
        userNotFound: 'Usuario no encontrado',
        wrongPassword: 'La contraseña actual es incorrecta',
        tooManyRequests:
          'Ha ralizado demasiadas solicitudes. Espere un momento y vuelve a intentarlo.',
        tooManyFailedAttempts: 'Has fallado en varios intentos',
        tooManyAttempts: 'Has excedido el límite de intentos',
        tokenSent: 'Código enviado al correo electrónico: {{email}}',
        passwordChanged: '¡Contraseña cambiada con éxito!',
        tokenInvalid:
          'El código proporcionado no es válido. Vuelva a solicitar un código.',
        passwordTooShort: 'La contraseña debe tener al menos 8 caracteres',
        tryAgain:
          '¡Hubo un problema al enviar tu información, inténtalo de nuevo!',
        partialSuccessData:
          'Sus datos se han actualizado con éxito, sin embargo, hubo un problema al cargar su foto.',
        partialSuccessImg:
          'Su foto se actualizó con éxito, sin embargo, hubo un problema al enviar sus datos.',
        invalidEmail: 'Introduzca un correo electrónico válido',
        emailExists:
          'Ya existe una cuenta con el correo electrónico proporcionado',
        phoneExists: 'Este número de teléfono ya está registrado',
        phoneTooShort: 'Número de teléfono no válido.',
        idTooShort: 'Número de identificación no válido.',
        dataUpdated: '¡Datos actualizados con éxito!',
        collectionDeleted: 'Colección eliminada con éxito!',
        alreadySignedUp:
          '¡Ya se ha registrado, inicie sesión con su nombre de usuario y contraseña!',
        invalidLink:
          '¡Este enlace no es válido, comuníquese con su administrador!',
        multipleImages: 'Envía solo un archivo',
        imageType:
          'Este tipo de archivo no es compatible. Extensiones válidas: {{support}}',
        imageSize:
          '¡El archivo es demasiado grande! ({{size}}) El tamaño máximo de carga es de {{limit}}.',
        agreement:
          'Debes aceptar las $t(labels.useTerms) y la $t(labels.politics)',
        guardianData:
          'Como eres menor de edad, necesitas introducir los $t(titles.guardianData)',
        unreachable:
          'Nuestro servidor está experimentando dificultades técnicas. Vuelve a intentarlo más tarde.',
        accessDenied: 'Inicie sesión de nuevo.',
        unverifiedEmail: 'Error: correo electrónico no verificado',
        autoLogin: 'Te estamos iniciando seción.',
        copySuccess: '¡Copiado en el portapapeles!',
        copyError: '¡Error al copiar!',
        unexistingModule: 'Contenido no existe',
        unavailable: 'Contenido no disponible',
        betterExp: 'Para una mejor experiencia, acceda a nuestra aplicación o a la versión desktop',
        desktop: 'computadora',
        appAvailable: 'Descargue la aplicación BioAtlas en las tiendas',
        support1: 'Póngase en contacto com nuestro',
        support2: 'equipo de soporte',
        crash: 'Algo salió mal con BioAtlas. $t(messages.support1)',
        appDenied:
          'Vimos que no tienes accesso al BioAtlas. $t(messages.support1)',
        contactSuccess1: '¡Hola! ¡Acabamos de recibir tu mensaje!',
        contactSuccess2:
          'Estamos muy contentos de saber que quieres hablar con nosotros. Muchas gracias por su interés en BioAtlas.',
        contactSuccess3:
          'Estamos revisando su mensaje y en breve nos pondremos en contacto con usted para responder a sus preguntas y comentarios.',
        whichContents: 'Cúales contenidos accesarás:',
        errorCollection:
          'Lo siento, ocurrió un error al intentar obtener tus colecciones',
        emptyCollections: 'Parece que aún no tienes ninguna colección. Elige cualquier modelo 3D, personalízalo a tu manera y guarda ;)',
        emptyCollectionsMobile: 'Parece que aún no tienes ninguna colección. Accede a tu aplicación para ver tus colecciones.',
        collectionRenamed: '¡Colección renombrada exitosamente!',
        folderRenamed: 'Carpeta renombrada exitosamente!',
        collectionMoved: '¡Colección movida exitosamente!',
        collecionDeleted: '¡Colección excluída exitosamente!',
        collecionShared: '¡Colección compartida exitosamente!',
        collectionUnshared: '¡Colección removida del usuario exitosamente!',
        collectionDuplicated: '¡Colección duplicada exitosamente!',
        collectionFolderCreated: '¡Carpeta creada exitosamente!',
        collectionFolderDuplicateErr: 'Carpeta con este nombre ya existe',
        demoPopUpText1: 'Tienes ',
        demoPopUpTextDays: 'día ',
        demoPopUpTextDaysPlural: 'días ',
        demoPopUpTextMonths: 'mes ',
        demoPopUpTextMonthsPlural: 'meses ',
        demoPopUpText2: 'de acceso demo',
        demoPopUpText3: ', tu opinión sobre nuestra plataforma es muy importante.',
        demoExpiredText: 'Su licencia de demostración ha caducado. Por favor contacte nuestro soporte.',
        demoExpiredBtn: 'Chatea con nuestro equipo',
        errorInputShared: 'No es posible compartir la colección en este email',
        by: 'De',
        deleteCollectionMsgSingular: 'Al eliminar esta carpeta también se eliminarán las siguientes colecciones o carpetas:',
        deleteCollectionMsgPlural: 'Al eliminar esta carpeta también se eliminarán las siguientes colecciones o carpetas:',
        newContentTitle: 'Consulta las últimas novedades de BioAtlas',
        newContentSubtitle: 'Nueva herramienta para guardar tus colecciones',
        newContentText: 'Ahora puedes crear una colección personalizada de contenido 3D. Con BioAtlas, puedes guardar estos modelos, organizarlos en carpetas y compartirlos de forma sencilla y rápida con tus amigos y alumnos.',
        newContentClose: 'Cerrar',
        to: 'para',
        thankYouRating: 'Gracias por tu valoración',
        pickRateError: 'Por favor, elija una calificación para este contenido o cambie su calificación/comentario anterior antes de enviarlo.'
      },
      swal: {
        success: 'Éxito',
        warning: 'Atención',
        error: '¡Ha ocurrido un problema!',
        access: 'No tienes accesso',
        crash: '¡Ups!'
      },
      banner: {
        title: '¿Sabías?',
        curiosity1:
          'El cerebro humano tiene aproximadamente 86 mil millones de neuronas. Solo el 19% de ellos están ubicados en la corteza cerebral, mientras que el cerebelo contiene alrededor del 80%. El resto se encuentra en el tronco cerebral.',
        curiosity2:
          'El cuerpo humano brilla en la oscuridad. Este proceso consiste en la emisión de biofotones, caracterizados por reacciones químicas que emiten pequeñas cantidades de luz en nuestras células. No notamos este brillo a simple vista ya que la intensidad es mucho más débil de lo que podemos detectar.'
      },
      alts: {
        load: 'Cargando animación',
        logo: 'Logotipo de BioAtlas',
        eye: 'Icono de ojo para habilitar la visibilidad de la contraseña',
        avatar: 'Foto de perfil de usuario',
        edit: 'Editar icono',
        computer:
          'Imagen de una computadora que le advierte que ha llegado un correo electrónico',
        check: 'Animación de un icono de cheque'
      },
      modules: {
        cytology: 'Citología',
        transports: 'Transporte en la Membrana',
        histology: 'Histología',
        systemicAnatomy: 'Anatomía Sistémica',
        topographicAnatomy: 'Anatomía Topográfica',
        completeAnatomy: 'Anatomía Completa',
        physiology: 'Fisiología',
        embryology: 'Embriología',
        radiology: 'Radiología'
      },
      categories: {
        all: 'Todos',
        tissues: 'Tejidos',
        systems: 'Sistemas',
        organs: 'Órganos Sensoriales',
        atlas: 'Atlas de Anatomía Humana',
        atlas2: 'Atlas Anatómico',
        cadaver: 'Cadáver Virtual'
      },
      subCategories: {
        epithelial: 'Tejido Epitelial',
        connective: 'Tejido Conectivo',
        circulatory: 'Circulatorio',
        articular: 'Articular',
        digestive: 'Digestivo',
        endocrine: 'Endocrino',
        skeletal: 'Esquelético',
        integumentary: 'Tegumentario',
        lymphoid: 'Linfático',
        muscular: 'Muscular',
        nervous: 'Nervioso',
        respiratory: 'Respiratorio',
        urogenital: 'Urogenital',
        male: 'Masculino',
        female: 'Femenino'
      },
      helpers: {
        rotateMouse:
          'Mantenga presionado el botón izquierdo del mouse y muévase para rotar el objeto',
        moveMouseBlade:
          'Mantenga presionado el botón izquierdo del mouse y arrastre para mover la cuchilla',
        moveMouseModel:
          'Mantenga presionado el botón derecho del mouse para mover el objeto en la pantalla',
        selectMouse:
          'Haga clic izquierdo una vez para seleccionar una estructura',
        zoomMouse:
          'Use el botón de desplazamiento del mouse para acercar o alejar',
        textboxMouse:
          'Presione shift en el teclado, haga clic y arrastre el botón izquierdo del mouse',
        cameraKeyboard:
          'Use la tecla (W) para moverse hacia arriba, la tecla (S) para moverse hacia abajo, la tecla (A) para moverse hacia la izquierda y la tecla (D) para moverse hacia la derecha',
        rotateKeyboard:
          'Mantenga presionado el botón derecho del mouse para mover el objeto en la pantalla',
        zoomKeyboard: 'Use las teclas + y - para hacer zoom',
        moveKeyboard: 'Usa las teclas de flecha para mover la cuchilla',
        rotatePad:
          'Mantén presionado el botón izquierdo del panel táctil y muévelo para rotar',
        movePad:
          'Mantenga presionado el botón derecho del panel táctil y mueva la cámara del objeto',
        zoomPad: 'Haga clic izquierdo una vez para seleccionar una estructura'
      },
      home: {
        banner: {
          title:
            'Contenido Virtual Interactivo 3D para la Enseñanza en el Área de la Salud',
          subtitle:
            'Explore contenido digital interactivo en 3D para la Enseñanza del ciclo básico de salud a través de un navegador web, una aplicación Android/iOS y gafas de realidad virtual'
        },
        specs: {
          title:
            '¡Explore los detalles del cuerpo humano en cualquier momento y en cualquier lugar!',
          item1: 'Acesso Multiplataforma',
          text1:
            'Acceda a través de cualquier navegador web, aplicación de Android o iOS, no se requieren complementos, no se requiere instalación',
          item2: 'Control de Acceso',
          text2:
            'Habilite, controle y analice el acceso de profesores y alumnos',
          item3: 'Disponible en 3 idiomas',
          text3: 'Contenido mapeado y traducido al portugués, español e inglés',
          item4: 'Integración',
          text4:
            'Embárcate en nuestro contenido en su sitio web a través de nuestra API',
          item5: 'Animaciones Interactivas 3D',
          text5:
            'Contenido diverso con animaciones 3D guiadas para facilitar la comprensión',
          item6: 'Realidad Virtual 3D',
          text6:
            'Involucre a estudiantes y profesores a través del entorno inmersivo de Realidad Virtual'
        },
        minibanner: 'Reduzca los costos del laboratorio de anatomía',
        allInOne: {
          title:
            'Una suite multiplataforma para acceder a una Biblioteca Digital Interactiva de contenidos 3D para la Enseñanza en Salud',
          text: 'BioAtlas es una solución que se adapta a diversas herramientas tecnológicas del mercado y permite navegar por Contenidos Digitales Interactivos 3D para la salud utilizando cualquier navegador web o aplicación móvil Android o iOS y Gafas de Realidad Virtual.'
        },
        teaser: 'Conozca un poco más sobre BioAtlas',
        dashboard: {
          title:
            'Dashboard con datos para acompañamiento de indicadores de uso',
          subtitle:
            'Más inteligencia para monitorear el uso del sistema y los resultados a su favor',
          text1:
            'Analice el uso, el compromiso, la performance y otras métricas clave para mejorar el aprendizaje',
          text2: 'Obtenga información de las interacciones de los usuarios'
        },
        quotes: {
          text1:
            'BioAtlas es una excelente herramienta para que el estudiante integre los diferentes contenidos de las ciencias biológicas y de la salud',
          position: 'Profesor de Fisiología Humana y Neurociencia en FMIT'
        },
        conclusion:
          'Conozca BioAtlas, sus posibilidades y asegure el conocimiento de la Salud de su Institución en cualquier escenario de aprendizaje.'
      },
      contents: {
        banner: {
          title:
            'Explore el cuerpo humano en detalle a través de modelos virtuales interactivos en 3D',
          subtitle:
            'BioAtlas te permite navegar por el cuerpo humano de forma virtual totalmente interactiva utilizando cualquier navegador web, smartphone o gafas de Realidad Virtual.',
          obs: '*Está disponible en 3 idiomas: portugués, inglés y español'
        },
        systems: {
          title: 'Sistemas del cuerpo humano',
          completeAnatomy: {
            text1:
              'tiene más de 2500 estructuras etiquetadas de todos los sistemas anatómicos del cuerpo humano. Este módulo está separado en modelos masculino y femenino y está organizado según sus 12 sistemas anatómicos y 9 regiones.',
            text2:
              'es una reconstrucción en 3D de un cuerpo humano real que fue donado para investigación*. Se congeló, se dividió en 1878 partes y se fotografió con equipos de muy alta resolución. El cadáver virtual ha conservado los colores, está segmentado y mapeado para una visualización completa.',
            obs1: '*La versión 3D en Realidad Virtual - VR - es una forma inmersiva para  entender y comprender el cuerpo humano a un paso del metaverso.',
            obs2: '*Conoce más sobre el Proyecto Visible Human'
          },
          topographicAnatomy: {
            text: 'Navegue por los Elementos Descriptivos del Cráneo y las regiones anatómicas: cabeza y cuello, miembro superior derecho, miembro superior izquierdo, espalda, tórax, abdomen, pelvis, miembro inferior derecho y miembro inferior izquierdo.'
          },
          systemicAnatomy: {
            text: 'Sistemas anatómicos: tegumentario, muscular, esquelético, articular, nervioso, linfático, digestivo, respiratorio, arterial, venoso, endocrino y urogenital.'
          },
          physiology: {
            text: 'Explore el funcionamiento normal y las funciones del cuerpo humano a través de contenido virtual interactivo en 3D:',
            item1: 'Ciclo del corazón',
            item2: 'Contracción de la fibra muscular',
            item3: 'Generación y Conducción del Potencial de Acción',
            item4: 'Hematosis',
            item5: 'Transmisión sináptica',
            item6: 'Ventilación pulmonar'
          }
        },
        cellStructure: {
          title: 'Estructura celular y microanatomía',
          cytology: {
            title: 'Citología 3D',
            text: 'El módulo de Citología permite al usuario interactuar con dos categorías de contenido 3D: Células y Membrana celular. Navega por las células: Célula Eucariota de origen animal, Célula Sanguínea, Neurona y Fibra Muscular. Visualice un modelo de membrana celular en 3D con cinco animaciones relacionadas con el transporte de moléculas a través de la membrana:',
            item1: 'Difusión simple',
            item2: 'Difusión facilitada',
            item3: 'Bomba de sodio y potasio',
            item4: 'Co-transporte',
            item5: 'Contratransporte'
          },
          histology: {
            text: 'BioAtlas dispone de un módulo dedicado al estudio de láminas de glándulas, órganos, tejidos y sistemas, y láminas con patologías. Además de disponer de una herramienta con recursos para su visualización y análisis.'
          }
        },
        development: {
          title: 'Desarrollo humano',
          embryology: {
            text: 'Realice un seguimiento del desarrollo embrionario y fetal, desde la quarta semana de gestación hasta el nacimiento, a través de animaciones 3D interactivas.'
          }
        },
        specialties: {
          title: 'Especialidades medicas',
          radiology: {
            text: 'Sistema de visualización de imágenes DICOM con herramientas para el manejo de casos y estudios clínicos radiológicos. Te permite visualizar imágenes de diferentes modalidades como tomografías computarizadas, resonancias magnéticas, radiografías, entre otras, a través del navegador web.'
          }
        },
        conclusion:
          'Póngase en contacto para obtener información sobre BioAtlas'
      },
      integration: {
        banner: {
          title: 'Agregue interactividad BioAtlas 3D a su contenido',
          subtitle:
            'Dé vida a su proyecto de atención médica agregando su marca a BioAtlas o incorporando nuestras animaciones, piezas virtuales interactivas a través de la integración API de BioAtlas.'
        },
        api: {
          label: 'API BioAtlas',
          title:
            'Contenido 3D de BioAtlas en su proyecto a través de nuestra API',
          subtitle:
            'Construya una experiencia interactiva virtual 3D en su proyecto relacionado con la anatomía u otras disciplinas relacionadas con el ciclo básico en Salud. El contenido de BioAtlas se puede integrar fácilmente en su sitio web, contenido digital, LMS o proyecto digital a través de la API de BioAtlas.'
        },
        institutions: {
          label: 'Instituciones',
          title: 'BioAtlas para su institución',
          item1: 'Farmacéutico',
          item2: 'Universidades',
          item3: 'Hospitales',
          item4: 'Editores'
        },
        whiteLabel: {
          label: 'White Label',
          title: 'BioAtlas con la marca de tu Institución',
          subtitle:
            'BioAtlas cuenta con una solución “Private Label”, que le permite marcar su Institución, convirtiéndola en una solución 100% personalizada.'
        }
      }
    }
  }
}

export { messages }
