import React, { useEffect, useRef, useState } from 'react'
import * as S from './styles'

import { useWindowSize } from '@/contexts/WindowSizeContext'

import Logo from '@/components/Header/Logo'
import EmailButton from '@/components/EmailButton'

import {
  COUNTRIES,
  getCountryId,
  getDefaultLanguage,
  changeClientLanguage,
  getLocalizedZendeskLink
} from '@/services/language'
import { LOCAL_KEYS } from '@/services/browserStorage'
import { EVENT_KEYS } from '@/services/events'
import { ASSET_PATHS, PAGE_PATHS } from '@/services/paths'

import { useTranslation } from 'react-i18next'
import ReactFlagsSelect from 'react-flags-select'

const FooterUnsigned = () => {
  const { t } = useTranslation()
  const { windowSize } = useWindowSize()

  const [titlesContents, setTitlesContents] = useState('')
  const [titlesIntegration, setTitlesIntegration] = useState('')
  const [titlesContact, setTitlesContact] = useState('')
  const [titlesHelp, setTitlesHelp] = useState('')
  const [titlesCompliance, setTitlesCompliance] = useState('')
  const [titlesOrgans, setTitlesOrgans] = useState('')
  const [titlesCellStructure, setTitlesCellStructure] = useState('')
  const [titlesDevelopment, setTitlesDevelopment] = useState('')
  const [titlesSpecialties, setTitlesSpecialties] = useState('')
  const [titlesAPI, setTitlesAPI] = useState('')
  const [titlesInstitutions, setTitlesInstitutions] = useState('')
  const [titlesWhiteLabel, setTitlesWhiteLabel] = useState('')
  const [subtitlesGroup, setSubtitlesGroup] = useState('')
  const [labelsUseTerms, setLabelsUseTerms] = useState('')
  const [labelsPolitics, setLabelsPolitics] = useState('')
  const [labelsCookies, setLabelsCookies] = useState('')
  const [labelsRequests, setLabelsRequests] = useState('')
  const [labelsContactWhats, setLabelsContactWhats] = useState('Whatsapp')

  const [whatsAppLink, setWhatsAppLink] = useState('')

  const [selectedFlag, setSelectedFlag] = useState()

  const didInit = useRef(false)

  useEffect(() => {
    setTitlesContents(t('titles.contents'))
    setTitlesIntegration(t('titles.integration'))
    setTitlesContact(t('titles.contact'))
    setTitlesHelp(t('titles.help'))
    setTitlesCompliance(t('titles.compliance'))
    setTitlesOrgans(t('titles.organs'))
    setTitlesCellStructure(t('titles.cellStructure'))
    setTitlesDevelopment(t('titles.development'))
    setTitlesSpecialties(t('titles.specialties'))
    setTitlesAPI(t('titles.api'))
    setTitlesInstitutions(t('titles.institutions'))
    setTitlesWhiteLabel(t('titles.whiteLabel'))
    setSubtitlesGroup(t('subtitles.group'))
    setLabelsUseTerms(t('labels.useTerms'))
    setLabelsPolitics(t('labels.politics'))
    setLabelsCookies(t('labels.cookies'))
    setLabelsRequests(t('labels.requests'))
    setLabelsContactWhats(t('labels.contactWhats'))
  }, [t])

  useEffect(() => {
    setWhatsAppLink(`https://wa.me/${labelsContactWhats.replace(/\D/g, '')}`)
  }, [labelsContactWhats])

  useEffect(() => {
    if (!windowSize.width) return

    const isDesktopSized = windowSize.width >= 1050
    if (isDesktopSized) {
      // Timeout para dar tempo do primeiro render, senão pega posição errada
      setTimeout(setNavPosition, 200)
    }
  }, [windowSize])

  useEffect(() => {
    if (didInit.current || typeof window === 'undefined') return

    didInit.current = true
    initObserver()

    const defaultLanguage = getDefaultLanguage()
    changeClientLanguage(defaultLanguage)
    setSelectedFlag(getCountryId(defaultLanguage))

    const listener = document.addEventListener(
      EVENT_KEYS.languageChange,
      () => {
        const lang = localStorage.getItem(LOCAL_KEYS.client_language)
        setSelectedFlag(getCountryId(lang))
      }
    )

    return () => {
      document.removeEventListener(EVENT_KEYS.languageChange, listener)
    }
  }, [])

  const initObserver = () => {
    const observer = new window.MutationObserver(event => {
      // console.log(event)
      setNavPosition()
    })
    const div = document.getElementById('contents-menu')
    observer.observe(div, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true
    })
  }

  const setNavPosition = () => {
    const nav = document.getElementById('button-menu')
    const div = document.getElementById('contents-menu')

    nav.style.left = `${div.offsetLeft}px`
  }

  const getCurrentYear = () => {
    return new Date().getFullYear()
  }

  return (
    <S.FooterContainer className="mw-unsigned">
      <S.NavMenu>
        <Logo />
        <div id="button-menu" className="flexbox v-centered">
          <a href={PAGE_PATHS.contents} id="btn_footer_contents">
            {titlesContents}
          </a>
          <a href={PAGE_PATHS.integration} id="btn_footer_integration">
            {titlesIntegration}
          </a>
          <a href={PAGE_PATHS.contact} id="btn_footer_contact">
            {titlesContact}
          </a>
          <a
            href={getLocalizedZendeskLink()}
            target="_blank"
            rel="noopener noreferrer"
            id="btn_footer_help"
          >
            {titlesHelp}
          </a>
        </div>
      </S.NavMenu>

      <S.LinkMenu>
        <div className="desktop-only">
          <div className="flexbox v-centered mb-4">
            <a
              href={PAGE_PATHS.facebook}
              target="_blank"
              rel="noopener noreferrer"
              id="btn_footer_facebook"
              className="social-link"
            >
              <img src={ASSET_PATHS.icon_facebook} alt="Facebook icon" />
            </a>
            <a
              href={PAGE_PATHS.instagram}
              target="_blank"
              rel="noopener noreferrer"
              id="btn_footer_instagram"
              className="social-link"
            >
              <img src={ASSET_PATHS.icon_instagram} alt="Instagram icon" />
            </a>
            <a
              href={PAGE_PATHS.youtube}
              target="_blank"
              rel="noopener noreferrer"
              id="btn_footer_youtube"
              className="social-link"
            >
              <img src={ASSET_PATHS.icon_youtube} alt="Youtube icon" />
            </a>
          </div>
          <div>
            <ReactFlagsSelect
              id="inp_flagselect_footer"
              value={selectedFlag}
              selected={selectedFlag}
              onSelect={code =>
                changeClientLanguage(COUNTRIES[code].lang, false)
              }
              className="menu-flags"
              selectButtonClassName="menu-flags-button"
              countries={[COUNTRIES.US.id, COUNTRIES.ES.id, COUNTRIES.BR.id]}
              customLabels={{
                US: 'English',
                BR: 'Português',
                ES: 'Español'
              }}
              showSelectedLabel={true}
              showOptionLabel={true}
              selectedSize={14}
            />
          </div>
        </div>

        <div id="contents-menu">
          <p>{titlesContents}</p>
          <a
            href={`${PAGE_PATHS.contents}?section=0#details`}
            id="btn_footer_organs"
            className="hover"
          >
            {titlesOrgans}
          </a>
          <a
            href={`${PAGE_PATHS.contents}?section=1#details`}
            id="btn_footer_cells"
            className="hover"
          >
            {titlesCellStructure}
          </a>
          <a
            href={`${PAGE_PATHS.contents}?section=2#details`}
            id="btn_footer_development"
            className="hover"
          >
            {titlesDevelopment}
          </a>
          <a
            href={`${PAGE_PATHS.contents}?section=3#details`}
            id="btn_footer_specialties"
            className="hover"
          >
            {titlesSpecialties}
          </a>
        </div>

        <div>
          <p>{titlesIntegration}</p>
          <a
            href={`${PAGE_PATHS.integration}?section=section_api`}
            id="btn_footer_api"
            className="hover"
          >
            {titlesAPI}
          </a>
          <a
            href={`${PAGE_PATHS.integration}?section=section_institutions`}
            id="btn_footer_institutions"
            className="hover"
          >
            {titlesInstitutions}
          </a>
          <a
            href={`${PAGE_PATHS.integration}?section=section_whitelabel`}
            id="btn_footer_whitelabel"
            className="hover"
          >
            {titlesWhiteLabel}
          </a>
        </div>

        <S.ContactsMenu className="desktop-only">
          <p>{titlesContact}</p>
          <a
            href={whatsAppLink}
            target="_blank"
            rel="noopener noreferrer"
            className="hover"
            id="btn_footer_whatsapp"
          >
            <img src={ASSET_PATHS.icon_whatsapp} alt="WhatsApp icon" />
            <span>{labelsContactWhats}</span>
          </a>
          <EmailButton id="footer" iconOutline={true} />
          <a
            href={getLocalizedZendeskLink()}
            target="_blank"
            rel="noopener noreferrer"
            className="hover"
            id="btn_footer_help2"
          >
            <span className="material-icons-round">support_agent</span>
            <span>{titlesHelp}</span>
          </a>
        </S.ContactsMenu>

        <div className="desktop-only">
          <p>{titlesCompliance}</p>
          <a
            href={PAGE_PATHS.terms}
            target="_blank"
            rel="noopener noreferrer"
            id="btn_footer_useterms"
            className="hover"
          >
            {labelsUseTerms}
          </a>
          <a
            href={PAGE_PATHS.privacy}
            target="_blank"
            rel="noopener noreferrer"
            id="btn_footer_privacy"
            className="hover"
          >
            {labelsPolitics}
          </a>
          <a
            href={PAGE_PATHS.cookies}
            target="_blank"
            rel="noopener noreferrer"
            id="btn_footer_cookies"
            className="hover"
          >
            {labelsCookies}
          </a>
          <a
            href={PAGE_PATHS.requests}
            target="_blank"
            rel="noopener noreferrer"
            id="btn_footer_requests"
            className="hover"
          >
            {labelsRequests}
          </a>
        </div>

        <div className="joined-col">
          <S.ContactsMenu>
            <p>{titlesContact}</p>
            <a
              href={whatsAppLink}
              target="_blank"
              rel="noopener noreferrer"
              id="btn_footer_whatsapp"
            >
              <img src={ASSET_PATHS.icon_whatsapp} alt="WhatsApp icon" />
              <span>{labelsContactWhats}</span>
            </a>
            <EmailButton id="footer" iconOutline={true} />
            <a
              href={getLocalizedZendeskLink()}
              target="_blank"
              rel="noopener noreferrer"
              className="hover"
              id="btn_footer_help2"
            >
              <span className="material-icons-round">support_agent</span>
              <span>{titlesHelp}</span>
            </a>
          </S.ContactsMenu>

          <div>
            <p>{titlesCompliance}</p>
            <a
              href={PAGE_PATHS.terms}
              target="_blank"
              rel="noopener noreferrer"
              id="btn_footer_useterms"
            >
              {labelsUseTerms}
            </a>
            <a
              href={PAGE_PATHS.privacy}
              target="_blank"
              rel="noopener noreferrer"
              id="btn_footer_privacy"
            >
              {labelsPolitics}
            </a>
            <a
              href={PAGE_PATHS.cookies}
              target="_blank"
              rel="noopener noreferrer"
              id="btn_footer_cookies"
            >
              {labelsCookies}
            </a>
            <a
              href={PAGE_PATHS.requests}
              target="_blank"
              rel="noopener noreferrer"
              id="btn_footer_requests"
            >
              {labelsRequests}
            </a>
          </div>
        </div>
      </S.LinkMenu>

      <S.MobileSocialLinks>
        <div>
          <ReactFlagsSelect
            id="inp_flagselect_footer"
            value={selectedFlag}
            selected={selectedFlag}
            onSelect={code => changeClientLanguage(COUNTRIES[code].lang, false)}
            className="menu-flags"
            selectButtonClassName="menu-flags-button"
            countries={[COUNTRIES.US.id, COUNTRIES.ES.id, COUNTRIES.BR.id]}
            customLabels={{
              US: 'English',
              BR: 'Português',
              ES: 'Español'
            }}
            showSelectedLabel={true}
            showOptionLabel={true}
            selectedSize={14}
          />
        </div>

        <div className="flexbox v-centered">
          <a
            href={PAGE_PATHS.facebook}
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
            id="btn_footer_facebook"
          >
            <img src={ASSET_PATHS.icon_facebook} alt="Facebook icon" />
          </a>
          <a
            href={PAGE_PATHS.instagram}
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
            id="btn_footer_instagram"
          >
            <img src={ASSET_PATHS.icon_instagram} alt="Instagram icon" />
          </a>
          <a
            href={PAGE_PATHS.youtube}
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
            id="btn_footer_youtube"
          >
            <img src={ASSET_PATHS.icon_youtube} alt="Youtube icon" />
          </a>
        </div>
      </S.MobileSocialLinks>

      <S.Copyright>
        <p className="desktop-only">
          <strong>{subtitlesGroup}</strong> © {getCurrentYear()} Medical Harbour
        </p>

        <div className="mobile-only">
          <p>
            <strong>{subtitlesGroup}</strong>
          </p>
          <p>© {getCurrentYear()} Medical Harbour</p>
        </div>
      </S.Copyright>
    </S.FooterContainer>
  )
}

export default FooterUnsigned
