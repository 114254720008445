const messages = {
  en: {
    translations: {
      titles: {
        home: 'Home',
        contents: 'Contents',
        integration: 'Integration',
        contact: 'Contact',
        compliance: 'Compliance',
        signup: 'Sign Up',
        signupSuccess: 'Registration Successful',
        signin: 'Sign In',
        login: 'Login',
        finalizeAccount: 'Complete your profile',
        guardianData: 'Legal Guardian Data',
        forgotPassword: 'Forgot my password',
        searchResults: 'Search results',
        pageNotFound: 'Page not found',
        adjustImage: 'Adjust your picture',
        account: 'Profile',
        admin: 'Admin',
        help: 'Help',
        rate: 'Rate',
        rated: 'Rated',
        rateQuestion: 'What did you think of this content?',
        profileData: 'Personal Info.',
        permissions: 'Notifications and Permissions',
        plan: 'Subscription',
        config: 'Settings',
        mouse: 'Mouse',
        keyboard: 'Keyboard',
        trackpad: 'Trackpad',
        organs: 'Bodies and Systems',
        completeAnatomy: 'Complete Anatomy',
        topographicAnatomy: 'Topographic Anatomy',
        systemicAnatomy: 'Systemic Anatomy',
        cellStructure: 'Cells and tissues',
        cytology: 'Cytology',
        histology: 'Histology',
        development: 'Bodily functions and development',
        embryology: 'Embryology',
        physiology: 'Physiology',
        specialties: 'Integrated Clinic',
        radiology: 'Radiology',
        pathology: 'Pathology',
        simulator: 'Simulator',
        api: 'BioAtlas API',
        institutions: 'Institutions',
        whiteLabel: 'White Label',
        VR: '3D Virtual Reality',
        multiplatform: 'Multiplataform Access',
        animations: '3D Interactive Animations',
        accessControl: 'Access Control',
        languages: 'Available in 3 languages',
        platform: 'Digital Content Platform to make Health Education easier',
        modules: 'Several modules for Health Education on a single platform',
        cadaver: 'Virtual Cadaver',
        cadaver3D: '3D Virtual Cadaver',
        atlas3D: '3D Anatomical Atlas',
        blades: 'Slides Histology and Histopathology',
        simulations: 'Medical Simulation',
        cardiacCycle: 'Cardiac Cycle of the Heart',
        fiberContraction: 'Muscle Fiber Contraction',
        potentialGeneration: 'Generation and Conduct of Action Potential',
        hematosis: 'Hematosis',
        synapticTransmission: 'Synaptic Transmission',
        lungVentilation: 'Lung Ventilation',
        pathophysiology: 'Pathophysiology',
        sickleAnemia: 'Sickle Cell Anemia',
        diabetes1: 'Diabetes Mellitus Type 01',
        diabetes2: 'Diabetes Mellitus Type 02',
        histopathology: 'Histopathology',
        respiratoryDiseases: 'Diseases of the Respiratory System',
        vascularDiseases: 'Diseases of the Cardiovascular System',
        bloodDiseases: 'Disorders of Blood Circulation',
        tumor: 'Tumor',
        brainDiseases: 'Diseases of the Nervous System',
        genitalDiseases: 'Diseases of the Genital System',
        inflammation: 'Inflammation',
        lesions: 'Injury and Repair of Cells and Tissues',
        endocrineDiseases: 'Diseases of the Endocrine System',
        immuneDiseases: 'Immune Diseases',
        boneDiseases: 'Diseases of the Muscular and Skeletal Systems',
        cardiology: 'Cardiology / Cardiac Emergencies',
        firstAid: 'First Aid',
        pulmonology: 'Pulmonology',
        infectology: 'Infectology',
        neurology: 'Neurology',
        vascular: 'Vascular',
        autoImmune: 'Rheumatism / Autoimmune',
        survey: 'Hi, will you help us improve BioAtlas?',
        collections: 'My collection',
        deleteCollections: 'Do you want to delete '
      },
      subtitles: {
        signup: 'Fill in the remaining information',
        validationSent: 'Information sent to email',
        signin: 'Log in to access your account.',
        finalizeAccount: 'Fill in the remaining information',
        fillInfo: 'Fill in the information below:',
        resetPassword: 'You need to change your password:',
        resetToken: 'You will receive a token to change your password',
        pageNotFound: 'Sorry, there was an error on our page.',
        rightsReserved: 'All rights reserved.',
        tabText: 'Interactive Digital Content for Health',
        group: 'We are an Afya Group company',
        sales: 'Contact our sales team',
        detail: 'Information',
        rotate: 'Rotate',
        moreTools: 'More tools',
        pin: 'Pin',
        ruler: 'Ruler',
        arrow: 'Arrow',
        savePin: 'Save pin',
        saveArrow: 'Save arrow',
        saveRectangle: 'Save rectangle',
        fullscreen: 'Full screen',
        info: 'Info',
        move: 'Move',
        select: 'Select',
        camera: 'Move camera',
        zoom: 'Zoom',
        resetZoom: 'Reset zoom',
        deselect: 'Delete selection',
        focus: 'Focus',
        selectMultiple: 'Select multiple',
        hide: 'Hide',
        isolate: 'Isolate',
        tree: 'Anatomy tree',
        textbox: 'Text box',
        tag: 'Tag',
        cutTool: 'Cut tool',
        turn: 'Turn',
        menu: 'Menu',
        increaseZoom: 'Increase zoom',
        decreaseZoom: 'Decrease zoom',
        originalZoom: 'Back to original version',
        bladeMessage:
          'For the keyboard shortcuts to work, click on the image first and then press the key',
        organ: 'Organ',
        tissue: 'Tissue',
        color: 'Coloring',
        section: 'Section',
        partners1: 'Join more than',
        partners2: 'users and',
        partners3: 'educational institutions',
        completeAnatomy:
          'Browse through the 3D human body through the Virtual Cadaver or the 3D Anatomical Atlas.',
        completeAnatomy2:
          'Human Anatomy is the science that studies the morphology and organization of the human body, through the observation and analysis of the constituent macroscopic structures. This analysis is correlated with the functioning of the various parts and the formation mechanisms and in response to environmental, genetic and temporal factors. It can be categorized, based on the separation of parts, into Organic Systems and Anatomical Regions. In this module, representative anatomical models of the male and female body were contemplated, in addition to the male virtual cadaver.',
        topographicAnatomy:
          'Interact with a tagged 3D anatomy overview and explore macroanatomy.',
        topographicAnatomy2:
          'Topographic Anatomy deals with the study of the separation of the human body into regions, in order to facilitate the understanding of the anatomical relationships between these disruptions of all systems in a given segment of the body. This method helps to master the general topography of the organs based on the perception of the functioning and location of all nearby and related parts. In this module, the anatomical regions were contemplated: Head and Neck, Chest, Abdomen, Pelvis, Back, Upper and Lower Limbs.',
        topographicAnatomy3:
          'Browse the Descriptive Elements of the Skull and anatomical regions: head and neck, right upper limb, left upper limb, back, thorax, abdomen, pelvis, right lower limb, and left lower limb.',
        systemicAnatomy:
          'Analyze all anatomical regions separated by organs and systems.',
        systemicAnatomy2:
          'Systemic Anatomy deals with the analytical study of the organic systems of the human body, establishing sets of organs and anatomical parts that perform, in an integrated manner, the same functions in biological mechanisms. Although each system performs a task In this module, the following systems were considered: Arterial, Articular, Digestive, Endocrine, Skeletal, Lymphatic, Muscular, Nervous, Respiratory, Tegumentary, Urogenital and Venous.',
        systemicAnatomy3:
          'Anatomical Systems: integumentary, muscular, skeletal, articular, nervous, lymphatic, digestive, respiratory, arterial, venous, endocrine and urogenital.',
        cytology:
          'Navigate through BioAtlas virtual 3D cells and discover more of this universe with the naked eye.',
        cytology2:
          'Cytology, also known as Cell Biology, is the science that studies cells and their organelles, as well as their functions and importance in the constitution of living beings. Considered as the physiological and morphological unit of organisms, the eukaryotic cell encompasses several smaller structures with specific metabolic functions - the organelles - responsible for ensuring the proper functioning of biological mechanisms. To study these structures invisible to the naked eye, it is necessary to use optical microscopes, equipment used to enlarge the image.',
        cytology3:
          'The Cytology module allows the user to interact with two categories of 3D content: Cells and Cell Membrane. Browse cells: Adipose Cell, Eukaryotic Cell of animal origin, Blood Cell, Islets of Langerhans, Neuron and Muscle Fiber. Visualize a 3D Cell Membrane model and animations related to the transport of molecules across the membrane: Simple Diffusion, Facilitated Diffusion, Sodium Potassium Pump, Cotransport and Countertransport.',
        histology:
          'A virtual microscopy laboratory at your disposal. Explore different types of tissues, systems and sensory organs with all the capabilities of a microscope.',
        histology2:
          'Histology is the science that studies the formation and composition of cells, organs and biological tissues, in addition to investigating their individual functions and how they relate to the proper functioning of the organism. The study of these microscopic structures is made from very thin cuts of biological tissue, previously fixed for preservation. Then, the study material is placed on glass slides and goes through the staining process, highlighting the components of that tissue. With the histological slides ready, the analysis is made in optical microscopes, equipment used to enlarge the image.',
        histology3:
          'BioAtlas has a module dedicated to the study of slides of glands, organs, tissues and systems, and slides with pathologies. In addition to having a tool with resources for viewing and analyzing them with a zoom of up to 40x, marking on the slides, mirroring, etc.',
        embryology:
          'Follow the embryonic and fetal development interactively, from the 4th week of pregnancy until birth, through interactive 3D animations.',
        embryology2:
          'Human Embryology is the science that studies the embryonic development of human beings, from fertilization to birth, characterizing each stage of formation of individuals. Therefore, embryology analyzes all the processes that occur in growth of the embryo, considering from the cell segmentation phase to the end of the formation of all organs and tissues. For this, it uses many concepts from other areas of knowledge such as Cytology, Anatomy, Histology, Physiology and others. In this module, the stage of organogenesis from the 4th week of embryonic development.',
        embryology3:
          'Follow embryonic and fetal development, from the 4th week of gestation to birth, through interactive 3D animations of the Embryonic Developmental Period and Fetal Developmental Period.',
        physiology:
          'Different animated physiological models allow the user to visualize, interact and understand the functioning of the human body in 3D, in addition to accompanying the stages of its development with descriptors.',
        physiology2:
          'Human Physiology is the science that studies the functioning of the human body through internal biological processes, integrating physical-chemical and anatomical knowledge in order to understand the behavior of the organism as a whole. These physiological processes occur in cells, tissues, organs and systems of the body, showing the interactions between them and also the interaction with the external environment. Therefore, the field of Physiology covers microscopic contents, at molecular and cellular level, and macroscopic ones.',
        physiology3:
          'Explore the normal functioning and functions of the human body through interactive 3D virtual content:',
        radiology:
          'View exams of different modalities such as CT Scans, Magnetic Resonances, X-Rays, among others.',
        radiology2:
          'System for viewing DICOM images with tools for handling radiological studies and clinical cases. It allows viewing images of different modalities such as CT scans, Magnetic Resonances, X-rays, among others through the web browser.',
        pathology:
          'Virtual 3D models and histological slides that allow the study of different morphological and functional alterations of the human body. It promotes the development of clinical-epidemiological reasoning.',
        pathology2:
          'Pathology is the science that studies diseases, considering their basic mechanisms and the structural, biochemical and functional alterations in cells, tissues and associated organs. In addition, it also studies the symptoms and clinical manifestations in patients Pathological processes and disorders can be understood from a comparison with healthy processes, using knowledge from other sciences, such as Physiology, Cytology, Histology, etc. Thus, pathology acts as a link between basic sciences and clinical medicine , serving as a scientific basis for training health professionals.',
        pathology3:
          'The module Pathology is divided into two categories: Histopathology and Pathophysiology. The first category has 100 histological slides that show pathologies in tissues and cells, from different systems and anatomical regions of the human body. The second category includes three-dimensional animations that represent unhealthy physiological processes (pathological), highlighting the changes that occur in the body under these conditions.',
        simulator:
          'Virtual simulator to experience emergency situations in practice in the medical clinic.',
        simulator2:
          "Combining gamification with health training, we offer a 100% digital Clinical Simulation module that offers the feeling of a face-to-face experience, fully focused on the student's decision-making. Learn through virtual cases to save real patients, based on problems that promote clinical reasoning.",
        features:
          'Browse through 3D Human Body details interactively anytime, anywhere!',
        VR: 'Engage students and teachers through the immersive environment of Virtual Reality',
        multiplatform: 'Access via any web browser, Android or iOS app',
        animations:
          'Various content with guided 3D animations for easy understanding',
        accessControl: 'Enable, control and analyze teacher and student access',
        integration: 'Embed our content on your website through our API',
        languages:
          'Content mapped and translated into Portuguese, Spanish and English',
        testimonial0_0:
          'BioAtlas is an excellent tool for the student to integrate the different contents of biological and health sciences',
        testimonial0_1:
          'Professor of Human Physiology and Neuroscience at FMIT',
        newsletter:
          "Get to know BioAtlas and its possibilities, ensuring your Institution's knowledge in Health in any learning scenario.",
        platform:
          'Enhance learning of human health disciplines through the use of interactive 3D digital content on a unified platform.',
        modules:
          'A virtual environment with thousands of possibilities. Content related to the teaching of human anatomy, such as the 3D Virtual Human Anatomical Atlas, the 3D Virtual Cadaver, contents of Physiology, Histology, Embryology, Cytology, Medical Simulation and several others.',
        cadaver:
          'Have a 3D reconstruction of a real human body available for virtual dissection and complement the teaching of the anatomy laboratory.',
        cadaver3D:
          "The Virtual Cadaver illustrates the anatomy of a real male body, with its well-preserved proportions and structures that reveal the details of the organism's internal morphology. After freezing and dissecting the body, all anatomical parts were photographed and reconstructed in 3D in order to facilitate the visualization and study of human anatomy.",
        atlas3D:
          'The Human Atlas brings together the anatomical structures that represent the human body. Most parts are similar to those of the male and female body, such as the Respiratory, Digestive, Muscular, Articular, Skeletal, Lymphatic and Endocrine systems. In the Urogenital, Arterial, Venous, Nervous and Integumentary systems, some structures are different, characterizing each biological sex.',
        blades:
          'Over 200 digitized slides, being able to enlarge up to 40x, make markings, notes and much more!',
        simulations:
          'Virtual simulator to experience emergency situations in practice in the medical clinic in a 100% interactive environment.',
        contents1:
          'Explore the Human Body in detail through interactive 3D virtual models',
        contents2:
          'Content that makes the learning of human health disciplines easier through interactivity. It integrates the areas of Morphological Sciences, Physiological Sciences, Human Sciences and Social Sciences Applied to Health.',
        organs:
          'Learn about human anatomy interactively through the male and female anatomical atlases, and through the virtual cadaver - a 3D reconstruction of a real human body. The macroscopic study of the human body is organized in complete anatomy, which presents the systems and anatomical parts in an integrated way; systemic anatomy and topographic anatomy, which study structures that form organic systems and anatomical regions.',
        cellStructure:
          'Navigate the cells of the human body through interactive 3D models, exploring the organelles that make up the different cell types and their functions. In addition, a set of histological slides allows the user to microscopically view various body structures, such as glands, organs and tissues.',
        development:
          'Study human physiology and embryology through virtual three-dimensional content that explores different physiological processes and stages of embryonic and fetal development. Animated models allow the user to visualize and understand the functioning of the human body, in addition to following the stages of its development - from the 4th week of gestation to birth.',
        specialties:
          'Explore medical knowledge in a practical way from different perspectives. Radiology can be studied through DICOM images, with tools for handling clinical cases. Get to know the pathology module, which uses 3D virtual models and histological slides that allow the study of various morphological and functional alterations of the human body. Also, access the virtual simulator to experience emergency situations in the medical clinic in practice.',
        survey:
          "We are inviting you to fill out a short form, it is simple and quick.",
        subtitleSuccessShareCollection: 'Emails with access',
        subtitleErrorShareCollection: 'An error happenned in the following e-mails'
      },
      placeholders: {
        suggestion: 'Leave your suggestion',
        email: 'Enter your email',
        phone: 'Enter your phone',
        password: 'Enter your password',
        newPassword: 'Create your password',
        token: 'Enter code',
        name: 'Enter your name',
        lastName: 'Enter your last name',
        idNumber: 'Enter your ID (only numbers)',
        search: 'Search',
        searchQuestion: 'What content do you want to study?',
        resultsFor: 'Search results for',
        find: 'Find',
        country: 'Enter your country',
        profession: 'Enter your occupation',
        countrySelect: 'Select your country',
        professionSelect: 'Select your occupation',
        institution: 'Enter your institution or company',
        departmentSelect: 'Pick a department',
        message: 'Type your message here',
        defaultCollectionTitle: 'No title',
        renameCollection: 'Add name for a new folder or template',
        moveCollection: 'Select the folder you want to move it to',
        shareCollection: 'Type the e-mail you want to share it with',
        createFolder: 'Type the name of the folder',
        newContent: 'What\'s new'
      },
      labels: {
        email: 'Email',
        password: 'Password',
        newPassword: 'New Password',
        oldPassword: 'Current password',
        repeatPassword: 'Repeat Password',
        token: 'Enter the code received',
        firstName: 'First Name',
        lastName: 'Last Name',
        fullName: 'Full name',
        guardianName: "Legal Guardian's Name",
        guardianIdNumber: "Legal Guardian's ID Number",
        minorName: "Minor's Name",
        country: 'Country',
        profession: 'Occupation',
        institution: 'Company or Institution',
        department: 'Which departament do you want to talk to?',
        isAfya: 'Does your institution belong to the Afya Group?',
        message: 'Message',
        phone: 'Phone Number',
        mobile: 'Cell Phone Number',
        language: 'Language',
        pt: 'Português',
        es: 'Español',
        en: 'English',
        undefined: 'Undefined',
        description: 'Description',
        details: 'Details',
        forbidden: 'Module not released',
        account: 'Account',
        personalAccount: 'Personal Account',
        corporateAccount: 'Corporate Account',
        legal: 'Legal',
        useTerms: 'Terms of Use',
        politics: 'Privacy Policy',
        cookies: 'Cookies Policy',
        requests: "Holder's orders",
        dragFile: 'Drag or',
        uploadFile: 'upload your file here',
        availability: 'Available in:',
        contactWhats: '+55 11 96340-1024',
        contactEmail: 'sales@medicalharbour.com',
        newModule: 'New Module',
        new: 'New',
        updated: 'Updated',
        newContent: 'New content',
        step: 'Step',
        renameCollection: 'Name of folder or template',
        moveCollection: 'Move to',
        shareCollection: 'Share folder or template',
        createdAt: 'Created on',
        folderName: 'Folder name'
      },
      buttons: {
        signup: 'Register',
        signin: 'Begin session',
        enter: 'Enter',
        save: 'Save',
        cancel: 'Cancel',
        return: 'Return',
        exit: 'Exit',
        edit: 'Edit',
        update: 'Update',
        view: 'View',
        subscribe: 'Subscribe',
        send: 'Send',
        adjust: 'Adjust',
        remember: 'Remember me',
        sendData: 'Send Data',
        sendToken: 'Send reset email',
        resendToken: 'Resend code',
        resetPassword: 'Reset password',
        changePassword: 'Change Password',
        changeProfile: 'Change Info.',
        changeLanguage: 'Change Language',
        changeAvatar: 'Change Photo',
        deleteAccount: 'Delete Account',
        confirmDelete: 'Delete anyway',
        cancelDelete: "Don't delete",
        viewPlan: 'Check all the services in your plan',
        finalizeAccount: 'Finalize Registration',
        navigation: 'Navegation Notions',
        shortcuts: 'Keyboard Shortcuts',
        demo: 'Request a Demo',
        discover: 'I want to learn about BioAtlas',
        openNewTab: 'Open in new tab',
        getNews: 'Receive news',
        knowMore: 'Learn more',
        knowContent: '$t(buttons.knowMore) about our content',
        knowModules: '$t(buttons.knowMore) about our modules',
        knowCadaver: '$t(buttons.knowMore) about the virtual cadaver',
        askDemo: 'Request a demo for your institution',
        askDemoNow: 'Request now the demo for your institution',
        surveyNo: 'No thanks',
        surveyYes: "Let's do the challenge",
        seeContent: 'See content',
        keepReading: 'keep reading',
        relatedContent: 'Related Content',
        bibliography: 'Bibliography',
        share: 'Share',
        rename: 'Rename',
        duplicate: 'Duplicate',
        move: 'Move',
        delete: 'Delete',
        createFolder: 'Create folder',
        sendFeedback: 'Send feedback',
        seeAll: 'see all'
      },
      messages: {
        conjunction: 'and',
        separator: 'or',
        agreeTerms1:
          'By registering, I agree that I have read and accepted the',
        agreeTerms2: 'I agree that I have read and accept the',
        agreeTermsMinorS:
          'I am under 18 and my legal guardian authorizes Medical Harbor to collect my personal data.',
        agreeTermsMinorL:
          'I am under 18 years old and my legal guardian authorizes Medical Harbor to collect my personal data for the purposes of electronic communications of offers and services (SMS, instant messages, telephone, e-mail) in accordance with the $t(labels.politics).',
        validationSent: 'We sent you an email to validate your registration!',
        contactAdmin: 'Please contact the admin of your system!',
        accountReady: 'Your access to BioAtlas is active!',
        dataSharing: 'Accept sharing your data for product offers',
        notifications:
          'Accept receiving notifications via SMS, Whatsapp or email?',
        deleteQuestion:
          'Are you sure you want to permanently delete your account?',
        deleteReminder1:
          'Deleting your BioAtlas account is a permanent action and cannot be undone.',
        deleteReminder2:
          "Once you delete your account, you will not be able to log back into the app, and any 3D models you've created and saved in your Library will be removed from our database permanently and cannot be restored.",
        trialWarning:
          'You are using the trial version. You still have {{count}} day left.',
        trialWarning_plural:
          'You are using the trial version. You still have {{count}} days left.',
        trialUpgrade: 'Obtain PRO version.',
        noContent: 'No content for',
        newContent: 'New content that may interest you',
        noAccount: "Don't have an account yet?",
        gotAccount: 'Already have an account?',
        passwordsDontMatch: 'Passwords must be equals!',
        notYetValidated:
          "You haven't validated your registration through the email yet!",
        notYetConfirmed:
          'You have not confirmed your registration yet. Please, check your email.',
        confirmAccount: 'Confirm your BioAtlas account',
        notYetConfirmed1: 'Before changing your password,',
        notYetConfirmed2: 'Before logging in,',
        notYetConfirmed3: 'confirm your account with the email we sent.',
        notYetConfirmed4: "Don't forget to check your spambox too, ok?",
        checkData: 'Check your details and try again',
        loginInvalid: 'Invalid username and/or password',
        userNotFound: 'User not found',
        wrongPassword: 'Password is incorrect',
        tooManyRequests:
          'You made too many requests. Please wait a moment and try again.',
        tooManyFailedAttempts: 'You failed several retries',
        tooManyAttempts: 'You have exceeded your retry limit',
        tokenSent: 'Code sent to email: {{email}}',
        passwordChanged: 'Password changed successfully!',
        tokenInvalid: 'Invalid code. Please request a new one.',
        passwordTooShort: 'Password must be at least 8 characters long',
        tryAgain:
          'There was a problem submitting your information. Please try again!',
        partialSuccessData:
          'Your profile data was updated successfully, but there was a problem submitting your picture.',
        partialSuccessImg:
          'Your picture was updated successfully, but there was a problem submitting your profile info.',
        invalidEmail: 'Please enter a valid email',
        emailExists: 'An account already exists with this email',
        phoneExists: 'This phone number is already registered',
        phoneTooShort: 'Invalid phone number.',
        idTooShort: 'Invalid ID number.',
        dataUpdated: 'Info. updated successfully!',
        collectionDeleted: 'Collection deleted successfully!',
        alreadySignedUp:
          'You have already created an account, login with your username and password!',
        invalidLink:
          'This link is not valid, please contact your administrator!',
        multipleImages: 'Send only one file',
        imageType:
          'This file type is not supported. Valid extensions: {{support}}',
        imageSize:
          'This file is too big! ({{size}}) Maximum upload size is {{limit}}.',
        agreement:
          'You must accept the $t(labels.useTerms) and the $t(labels.politics)',
        guardianData:
          'As you are a minor, you need to enter the $t(titles.guardianData)',
        unreachable:
          'Our server is experiencing technical difficulties. Please try again later.',
        accessDenied: 'Please login again.',
        unverifiedEmail: 'Error: email not verified',
        autoLogin: 'We are logging you in.',
        copySuccess: 'Copied to clipboard!',
        copyError: 'There was an error copying!',
        unexisting: 'Content does not exist',
        unavailable: 'Content not available',
        betterExp: 'For a better experience, access our application or Desktop version',
        desktop: 'Desktop',
        appAvailable: 'Download the BioAtlas app from the stores',
        support1: 'Please contact our',
        support2: 'support team',
        crash: 'Something went wrong with BioAtlas. $t(messages.support1)',
        appDenied:
          "It seems that you don't have access to BioAtlas. $t(messages.support1)",
        contactSuccess1: "Hi! We've just received your message!",
        contactSuccess2:
          "We're thrilled to hear that you want to talk to us. Thank you very much for your interest in BioAtlas.",
        contactSuccess3:
          "We're reviewing your message and will contact you shortly to respond to your questions and comments.",
        whichContents: "Contents you'll access:",
        errorCollection:
          "I'm sorry, an error happened when trying to get your collections",
        emptyCollections: 'It looks like you don\'t have any collections yet. Choose any 3D model, customize it and save ;)',
        emptyCollectionsMobile: 'It looks like you don\'t have any collections yet. Access your app to view your collections.',
          collectionRenamed: 'Collection renamed successfully!',
        folderRenamed: 'Folder renamed successfully!',
        collectionMoved: 'Collection moved successfully!',
        collecionDeleted: 'Collection deleted successfully!',
        collecionShared: 'Collection shared successfully!',
        collectionUnshared: 'Collection was removed from user successfully!',
        collectionDuplicated: 'Collection duplicated successfully!',
        collectionFolderCreated: 'Folder created successfully!',
        collectionFolderDuplicateErr: 'Folder with this name already exists',
        demoPopUpText1: 'You have ',
        demoPopUpTextDays: 'day ',
        demoPopUpTextDaysPlural: 'days ',
        demoPopUpTextMonths: 'month ',
        demoPopUpTextMonthsPlural: 'months ',
        demoPopUpText2: 'left on your demo access',
        demoPopUpText3: ', your opinion about the platform is very important',
        demoExpiredText: 'Your demo license has expired. Please contact our support.',
        demoExpiredBtn: 'Chat with our team',
        errorInputShared: 'It is not possible to share the collection with this email',
        by: 'By',
        deleteCollectionMsgSingular: 'When deleting this folder the following collection or folder will also be deleted:',
        deleteCollectionMsgPlural: 'When deleting this folder the following models or collections will also be deleted:',
        newContentTitle: 'Check out the latest news from BioAtlas',
        newContentSubtitle: 'New tool to save your collections',
        newContentText: 'Now you can create a custom collection of 3D content. With BioAtlas, you can save these models, organize them into folders and share them simply and quickly with your friends and students.',
        newContentClose: 'Close',
        to: 'to',
        thankYouRating: 'Thank you for your rating',
        pickRateError: 'Please, pick a rating for this content or change your previous rate/comment before submitting'
      },
      swal: {
        success: 'Success',
        warning: 'Attention',
        error: 'A problem has ocurred!',
        access: 'You do not have access',
        crash: 'Oops!!!'
      },
      banner: {
        title: 'Did You Know?',
        curiosity1:
          'The human brain contains on average 86 billion neurons. Only 19% of them are located in cerebral cortex, while the cerebellum contains 80%. The remaining cells are found in the brainstem.',
        curiosity2:
          'The human body literally glimmers. This process consists of a ultraweak biophoton emission, energy released as light through the biochemical reactions in cell metabolism. The intensity of the light emitted by the body is lower than the sensitivity of our naked eyes, that’s why we cannot see it.'
      },
      alts: {
        load: 'Loading animation',
        logo: 'BioAtlas logo',
        eye: 'Eye icon for enabling password visibility',
        avatar: "User's profile picture",
        edit: 'Edit icon',
        computer: 'Image of a computer alerting that an email has arrived',
        check: 'Check icon animation'
      },
      modules: {
        cytology: 'Cytology',
        transports: 'Membrane Transport',
        histology: 'Histology',
        systemicAnatomy: 'Systemic Anatomy',
        topographicAnatomy: 'Topographic Anatomy',
        completeAnatomy: 'Complete Anatomy',
        physiology: 'Physiology',
        embryology: 'Embryology',
        radiology: 'Radiology'
      },
      categories: {
        all: 'All',
        tissues: 'Tissues',
        systems: 'Systems',
        organs: 'Sensory Organs',
        atlas: 'Human Atlas',
        atlas2: 'Anatomical Atlas',
        cadaver: 'Virtual Cadaver'
      },
      subCategories: {
        epithelial: 'Epithelial Tissue',
        connective: 'Connective Tissue',
        circulatory: 'Circulatory',
        articular: 'Articular',
        digestive: 'Digestive',
        endocrine: 'Endocrine',
        skeletal: 'Skeletal',
        integumentary: 'Integumentary',
        lymphoid: 'Lymphoid',
        muscular: 'Muscular',
        nervous: 'Nervous',
        respiratory: 'Respiratory',
        urogenital: 'Urogenital',
        male: 'Male',
        female: 'Female'
      },
      helpers: {
        rotateMouse: 'Hold the left mouse button and move to rotate the object',
        moveMouseBlade: 'Hold the left mouse button and drag to move the slide',
        moveMouseModel:
          'Hold the right mouse button to move the object on the screen',
        selectMouse: 'Left click once to select a structure',
        zoomMouse: 'Use the mouse scroll wheel to increase or decrease zoom',
        textboxMouse:
          'Press shift on the keyboard, click and drag the left mouse button',
        cameraKeyboard:
          'Use the (W) key to move up, (S) key to move down, (A) key to move left and (D) key to move right',
        rotateKeyboard:
          'Hold the right mouse button to move the object on the screen',
        zoomKeyboard: 'Use the + and - keys to apply zoom',
        moveKeyboard: 'Use the arrow keys to move the slide',
        rotatePad: 'Hold the left trackpad button and move to rotate',
        movePad: 'Hold the right trackpad button and move the object camera',
        zoomPad: 'Left click once to select a structure'
      },
      home: {
        banner: {
          title:
            '3D Interactive Virtual Content for Teaching in the HealthCare Area',
          subtitle:
            'Explore 3D interactive digital content for Teaching Basic Health Cycle through a web browser, Android/iOS App and Virtual Reality glasses'
        },
        specs: {
          title:
            'Browse through the details of the Human Body anytime, anywhere!',
          item1: 'Multiplatform Access',
          text1:
            'Access through any web browser, Android or iOS app, no plugins required, no installation required',
          item2: 'Access Control',
          text2: 'Enable, control and analyze teacher and student access',
          item3: 'Available in 3 languages',
          text3:
            'Content mapped and translated into Portuguese, Spanish and English',
          item4: 'Integration',
          text4: 'Embed our content on your website via our API',
          item5: '3D Interactive Animations',
          text5:
            'Diverse content with guided 3D animations for easy understanding',
          item6: '3D Virtual Reality',
          text6:
            'Engage students and teachers through the immersive environment of Virtual Reality'
        },
        minibanner: 'Reduce the Anatomy lab costs',
        allInOne: {
          title:
            'A multiplatform suite for accessing an Interactive Digital Library of 3D content for Health Education',
          text: 'BioAtlas is a solution that adapts to various technological tools on the market and allows browsing through 3D Interactive Digital Content for health using any web browser or mobile app Android or iOS and Virtual Reality Glasses'
        },
        teaser: 'Find out more about BioAtlas',
        dashboard: {
          title: 'Dashboard with data for tracking usage indicators',
          subtitle:
            'More intelligence to monitor system usage and results in your favor',
          text1:
            'Analyze usage, engagement, performance and other key metrics to improve learning',
          text2: 'Gain insights from user interactions'
        },
        quotes: {
          text1:
            'BioAtlas is an excellent tool for the student to integrate the different contents of biological and health sciences',
          position: 'Professor of Human Physiology and Neuroscience at FMIT'
        },
        conclusion:
          "Learn about BioAtlas, its possibilities, and ensure your Institution's knowledge of Health in any learning scenario."
      },
      contents: {
        banner: {
          title:
            'Explore the Human Body in detail through interactive 3D virtual models',
          subtitle:
            'BioAtlas allows you to navigate through the human body in a fully interactive virtual way using any web browser, smartphone or Virtual Reality glasses.',
          obs: '*Available in 3 languages: Portuguese, English and Spanish'
        },
        systems: {
          title: 'Human body systems',
          completeAnatomy: {
            text1:
              'has more than 2500 labeled structures of all anatomical systems of the human body. This module is separated into Male and Female models and organized according to 12 anatomical systems and 9 regions.',
            text2:
              'is a 3D reconstruction of a real human body that was donated for research*. It was frozen, divided into 1878 slices and photographed with very high resolution equipment. The Virtual Cadaver has preserved colors, is segmented and mapped for complete viewing.',
            obs1: '*The 3D version in Virtual Reality - VR - is an immersive way to understand and understand the human body one step away from the metaverse.',
            obs2: '*Learn more about the Visible Human Project'
          },
          topographicAnatomy: {
            text: 'Navigate through the Anatomical Landmarks of Skull and through anatomical regions: head and neck, right upper limb, left upper limb, back, chest, abdomen, pelvis, right lower limb and left lower limb.'
          },
          systemicAnatomy: {
            text: 'Anatomical systems: integumentary, muscular, skeletal, articular, nervous, lymphatic, digestive, respiratory, arterial, venous, endocrine and urogenital.'
          },
          physiology: {
            text: 'Explore the normal functioning and functions of the human body through interactive 3D virtual content:',
            item1: 'Heart Cycle',
            item2: 'Muscle fiber contraction',
            item3: 'Generation and Conduction of Action Potential',
            item4: 'Gas Exchange',
            item5: 'Synaptic Transmission',
            item6: 'Pulmonary Ventilation'
          }
        },
        cellStructure: {
          title: 'Cell structure and microanatomy',
          cytology: {
            title: '3D Cytology',
            text: 'The Cytology module allows the user to interact with two categories of 3D content: Cells and Cell Membrane. Navigate through the cells: Eukaryotic Cell of Animal Origin, Blood Cell, Neuron and Muscle Fiber. Visualize a 3D Cell Membrane model with five animations related to the transport of molecules across the membrane:',
            item1: 'Simple Diffusion',
            item2: 'Facilitated Diffusion',
            item3: 'Sodium and potassium pump',
            item4: 'Co-transport',
            item5: 'Countertransport'
          },
          histology: {
            text: 'BioAtlas has a module dedicated to the study of slides of glands, organs, tissues and systems, and slides with pathologies. In addition to having a tool with resources for viewing and analyzing them.'
          }
        },
        development: {
          title: 'Human development',
          embryology: {
            text: 'Visualize embryonic and fetal development, from the 4th week of gestation to birth, through interactive 3D animations.'
          }
        },
        specialties: {
          title: 'Medical specialties',
          radiology: {
            text: 'DICOM image visualization system with tools for handling radiological clinical studies and cases. It allows you to view images of different modalities such as CT scans, MRIs, X-Rays, among others, through the web browser.'
          }
        },
        conclusion: 'Get in contact to learn about BioAtlas'
      },
      integration: {
        banner: {
          title: 'Add BioAtlas 3D interactivity to your content',
          subtitle:
            'Bring your healthcare project to life by adding your brand to BioAtlas or by embedding our animations, interactive virtual pieces through BioAtlas API integration.'
        },
        api: {
          label: 'BioAtlas API',
          title: 'BioAtlas 3D content in your project via our API',
          subtitle:
            'Build a 3D virtual interactive experience in your project related to anatomy or other disciplines related to the basic cycle in Health. BioAtlas content can be easily integrated into your website, digital content, LMS or digital project through the BioAtlas API.'
        },
        institutions: {
          label: 'Institutions',
          title: 'BioAtlas for your institution',
          item1: 'Pharmaceutical',
          item2: 'Universities',
          item3: 'Hospitals',
          item4: 'Publishers'
        },
        whiteLabel: {
          label: 'White Label',
          title: "BioAtlas with your Institution's brand",
          subtitle:
            'BioAtlas has a “Private Label” solution, which allows you to brand your Institution, making it a 100% customized solution.'
        }
      }
    }
  }
}

export { messages }
