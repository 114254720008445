import React, { useEffect } from 'react'

import { LANDINGPAGE_DATA } from '@/page_data/landingPage'

import BannerSection from '@/components/LandingPage/BannerSection'
import Partners from '@/components/LandingPage/PageComponents/Landing/Partners'
import ContentSection from '@/components/LandingPage/PageComponents/Landing/ContentSection'
import { ContentSectionWrapper } from '@/components/LandingPage/PageComponents/Landing/ContentSection/styles'
import Features from '@/components/LandingPage/PageComponents/Landing/Features'
import Testimonials from '@/components/LandingPage/PageComponents/Landing/Testimonials'

import { EVENT_KEYS } from '@/services/events'

// Altura que a tela deve passar do topo da seção para trigar animação
const SECTION_OFFSET = 100

const LandingPage = () => {
  useEffect(() => {
    const listener = document.addEventListener(
      EVENT_KEYS.scroll,
      scrollCallback
    )
    return () => {
      document.removeEventListener(EVENT_KEYS.scroll, listener)
    }
  }, [])

  const scrollCallback = () => {
    const scroll = window.scrollY + window.innerHeight
    const inactiveSections = document.querySelectorAll(
      `div[id^='content-section']:not(.visible)`
    )

    inactiveSections.forEach(section => {
      if (scroll >= section.offsetTop + SECTION_OFFSET) {
        section.classList.add('visible')
      }
    })
  }

  return (
    <>
      <BannerSection
        data={LANDINGPAGE_DATA.banner}
        // clickActions={[{ index: 0, callback: () => setShowModal(true) }]}
      />
      <Partners data={LANDINGPAGE_DATA.partners} />
      <ContentSectionWrapper className="mw-unsigned">
        {LANDINGPAGE_DATA.contentSections.map((section, i) => (
          <ContentSection key={i} data={section} index={i} />
        ))}
      </ContentSectionWrapper>
      <Features data={LANDINGPAGE_DATA.features} />
      <Testimonials data={LANDINGPAGE_DATA.testimonials} />
    </>
  )
}

export default LandingPage
