import React, { useEffect, useRef } from 'react'
import Head from 'next/head'
import { GlobalStyle } from '@/styles/global'

import { WindowSizeProvider } from '@/contexts/WindowSizeContext'
import { AuthProvider } from '@/contexts/AuthContext'
import { ModalProvider } from '@/contexts/ModalContext'
import Tracked from '@/helpers/PageTypes/Tracked'

import ErrorBoundary from '@/components/ErrorBoundary'
import Modal from '@/components/Modal'

import { appWithTranslation } from 'next-i18next'
import TagManager from 'react-gtm-module'
import { AwsRum } from 'aws-rum-web'
import { datadogRum } from '@datadog/browser-rum'


const BioAtlas = ({ Component, pageProps }) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) return

    // -------- GOOGLE TAG MANAGER --------- //
    TagManager.initialize({
      gtmId: process.env.GTM_ID,
      auth: process.env.GTM_AUTH,
      preview: process.env.GTM_PREVIEW
    })

    // -------- MAZE --------- //
    const maze = (m, a, z, e) => {
      var s, t
      try {
        t = m.sessionStorage.getItem('maze-us')
      } catch (err) {}

      if (!t) {
        t = new Date().getTime()
        try {
          m.sessionStorage.setItem('maze-us', t)
        } catch (err) {}
      }

      s = a.createElement('script')
      s.src = z + '?t=' + t + '&apiKey=' + e
      s.async = true
      a.getElementsByTagName('head')[0].appendChild(s)
      m.mazeUniversalSnippetApiKey = e
    }

    maze(
      window,
      document,
      'https://snippet.maze.co/maze-universal-loader.js',
      '70a32d99-d483-484b-ba98-f1ef745c49d7'
    )

    // -------- AWS RUM --------- //
    try {
      const config = {
        sessionSampleRate: 1,
        identityPoolId: process.env.RUM_APP_POOL,
        endpoint: process.env.RUM_APP_ENDPOINT,
        telemetries: ["performance","errors","http"],
        allowCookies: true,
        enableXRay: true
      };
    
      const APPLICATION_ID = process.env.RUM_APP_ID;
      const APPLICATION_VERSION = '1.0.0';
      const APPLICATION_REGION = process.env.RUM_APP_REGION;
    
      const awsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
      );
      
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
    // ---------- DATADOG RUM --------- //
    try {
      datadogRum.init({
        applicationId: process.env.DATADOG_APPLICATION_ID,
        clientToken: process.env.DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'bioatlas',
        env: process.env.DATADOG_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: process.env.NEXT_PUBLIC_DATADOG_APP_VERSION,
        sessionSampleRate: 100,
        sessionReplaySampleRate: +process.env.DATADOG_SAMPLE_RATE,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        storeContextsAcrossPages: true
    });
    } catch (error) {
      // Ignore errors thrown during DATADOG RUM web client initialization
    }

    didMount.current = true
  }, [])

  return (
    <Tracked>
      <WindowSizeProvider>
        <AuthProvider tokenData={pageProps?.tokenData}>
          <ModalProvider>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, minimum-scale=1"
              />
            </Head>
            <ErrorBoundary>
              <Component {...pageProps} />
              <Modal />
            </ErrorBoundary>
            <GlobalStyle />
          </ModalProvider>
        </AuthProvider>
      </WindowSizeProvider>
    </Tracked>
  )
}

export default appWithTranslation(BioAtlas)
